var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-AppAmountSelector rounded-full grid grid-cols-3 gap-1 items-center",class:{ 'bg-primary-brand': _vm.primaryColor, 'bg-day-on-primary dark:bg-night-on-surface': !_vm.primaryColor }},[_c('AppIcon',{staticClass:"py-2 pl-2 cursor-pointer flex justify-center",class:{
            'text-day-on-surface-medium-emphasis dark:text-night-on-surface-low-emphasis-disabled': _vm.amount === 0 && !_vm.primaryColor,
            'text-day-on-surface-low-emphasis-disabled': _vm.amount === 0 && _vm.primaryColor,
            'text-white': _vm.primaryColor,
        },attrs:{"icon-name":"minus","width":16,"height":16},on:{"click":function($event){_vm.amount > 0 ? _vm.$emit('decrease') : null}}}),_c('span',{staticClass:"text-center leading-4 font-medium",class:{ 'text-white': _vm.primaryColor }},[_vm._v(_vm._s(_vm.amount))]),_c('AppIcon',{staticClass:"py-2 pr-2 cursor-pointer flex justify-center",class:{
            'text-day-on-surface-medium-emphasis dark:text-night-on-surface-low-emphasis-disabled': _vm.amount >= _vm.max && !_vm.primaryColor,
            'text-day-on-surface-low-emphasis-disabled': _vm.amount >= _vm.max && _vm.primaryColor,
            'text-white': _vm.primaryColor,
        },attrs:{"icon-name":"plus","width":16,"height":16},on:{"click":function($event){_vm.amount < _vm.max ? _vm.$emit('increase') : null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }