<template>
    <!-- eslint-disable max-len -->
    <div class="ui-AppAmountSelector rounded-full grid grid-cols-3 gap-1 items-center"
        :class="{ 'bg-primary-brand': primaryColor, 'bg-day-on-primary dark:bg-night-on-surface': !primaryColor }">

        <AppIcon @click="amount > 0 ? $emit('decrease') : null"
            icon-name="minus"
            :width="16"
            :height="16"
            class="py-2 pl-2 cursor-pointer flex justify-center"
            :class="{
                'text-day-on-surface-medium-emphasis dark:text-night-on-surface-low-emphasis-disabled': amount === 0 && !primaryColor,
                'text-day-on-surface-low-emphasis-disabled': amount === 0 && primaryColor,
                'text-white': primaryColor,
            }" />

        <span class="text-center leading-4 font-medium" :class="{ 'text-white': primaryColor }">{{ amount }}</span>

        <AppIcon
            @click="amount < max ? $emit('increase') : null"
            icon-name="plus"
            :width="16"
            :height="16"
            class="py-2 pr-2 cursor-pointer flex justify-center"
            :class="{
                'text-day-on-surface-medium-emphasis dark:text-night-on-surface-low-emphasis-disabled': amount >= max && !primaryColor,
                'text-day-on-surface-low-emphasis-disabled': amount >= max && primaryColor,
                'text-white': primaryColor,
            }"  />
    </div>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
    name: 'UIAppAmountSelector',

    props: {
        amount: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            default: 99999,
        },
        primaryColor: Boolean,
    },

    components: {
        AppIcon,
    },
};
</script>

<style lang="scss" scoped>
.ui-AppAmountSelector {}
</style>
