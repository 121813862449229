<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" :allow-close="!refillReference && !initializingRefill" class="operator-OperatingDrawer z-40">
        <div v-if="machineOperatorData" class="operator-OperatingDrawer_Shelves grid row-gap-4 mt-6 pb-8">
            <MachineShelve
                v-for="shelve in machineOperatorData.shelves"
                :key="shelve.position"
                :shelve="shelve"
                :refill-reference="refillReference" />
        </div>

        <div v-if="!initializingRefill" class="operator-OperatingDrawer_Actions">
            <AppButton v-if="!refillReference" @click="initiateNewRefill" size="small" icon="unlock" width="full">{{ $t('operator.buttons.click-to-unlock') }}</AppButton>
            <AppButton v-if="refillReference" @click="finishRefill" size="small" icon="lock" width="full">{{ $t('operator.buttons.click-to-stop') }}</AppButton>
        </div>
    </BaseDrawer>
</template>

<script>
/* eslint-disable max-len */
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import MachineShelve from '@/components/Operator/MachineShelve.vue';
import useMachine from '@/composables/useMachine';
import AppButton from '@/components/Interface/AppButton.vue';
import { onBeforeMount, ref } from '@vue/composition-api';

export default {
    name: 'OperatingDrawer',

    components: {
        BaseDrawer,
        MachineShelve,
        AppButton,
    },

    setup(_, { root, emit }) {
        const { fetchMachineData, code, machineOperatorData } = useMachine();
        const refillReference = ref(null);
        const initializingRefill = ref(false);

        /**
         * Method to fetch machine operator data
         *
         * @async
         * @returns {void}
         */
        const fetchMachineOperatorData = async () => {
            try {
                const response = await root.$http.get(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}`);
                machineOperatorData.value = response.data.data;
            } catch (error) {
                root.$router.push({ name: 'ServerError' });
            }
        };

        onBeforeMount(async () => {
            await fetchMachineOperatorData();
        });

        /**
         * Method to initate a new refill
         */
        const initiateNewRefill = async () => {
            initializingRefill.value = true;

            try {
                const response = await root.$http.post(`${process.env.VUE_APP_OPERATOR_API_URL}/machine/${code.value}/init-refill`);
                refillReference.value = response.data.data.reference;

                initializingRefill.value = false;

                root.$toast.open({
                    message: root.$t('operator.notifications.door-unlocked'),
                    type: 'success',
                });
            } catch (error) {
                root.$router.push({ name: 'ServerError' });
            }
        };

        /**
         * Method to finish refill
         */
        const finishRefill = async () => {
            try {
                const requestUrl = `${process.env.VUE_APP_OPERATOR_API_URL}/refill/${refillReference.value}`;
                await root.$http.put(requestUrl, {
                    machine: code.value,
                });

                root.$toast.open({
                    message: root.$t('operator.notifications.saved'),
                    type: 'success',
                });

                refillReference.value = null;

                // Refetch machine data for new stock values
                fetchMachineData(code.value);

                emit('close');
            } catch (error) {
                console.log(error);
            }
        };

        return {
            machineOperatorData,
            refillReference,
            initiateNewRefill,
            initializingRefill,
            finishRefill,
        };
    },
};
</script>

<style lang="scss" scoped>
.operator-OperatingDrawer {

    &_Actions {
        position: sticky;
        width: 100%;
        bottom: 0;
        z-index: 40;
    }
}
</style>
