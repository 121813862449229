<template>
    <!-- eslint-disable max-len -->
    <header class="layout-TheOrderHeader flex justify-between items-center pt-8 pb-10 px-8 bg-day-surface dark:bg-night-surface">
        <!-- <button v-if="machineType === 'fridge'" @click="toggleFridgeDisconnectDialog" class="layout-TheOrderHeader_DisconnectFridge pr-4">
            <AppIcon v-if="!isInitializingTouch" icon-name="exit" :width="24" :height="24" :class="['text-day-on-surface-high-emphasis', {
                'dark:text-night-on-surface-medium-emphasis': cartAmount > 0,
                'dark:text-night-on-surface-ultra-emphasis': cartAmount == 0,
            }]" />
        </button> -->

        <div v-if="machine" class="layout-TheOrderHeader_Machine flex items-center flex-grow">
            <img :src="require(`@/assets/images/machine-types/${machine.type}.png`)" :alt="machine.name" class="h-12" />

            <div class="layout-TheOrderHeader_MachineInfo ml-4">
                <h3 class="font-medium leading-none">{{ machine.name }}</h3>

                <div class="flex items-center text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
                    <AppIcon icon-name="marker" :width="12" :height="12" />
                    <span class="ml-1 font-normal">{{ machine.location_name }}</span>
                </div>
            </div>
        </div>

        <button @click="toggleMachineOptions" class="layout-TheOrderHeader_Exit">
            <AppIcon icon-name="ellipsis-vertical" :width="24" :height="24" />
        </button>

        <portal to="dialogs" :order="1">
            <MachineOptionsDrawer
                v-if="machineOptionsOpened"
                @close="toggleMachineOptions" />

            <DisconnectDialog
                v-if="fridgeDisconnectDialogOpen"
                @close="toggleFridgeDisconnectDialog" />
        </portal>
    </header>
</template>

<script>
import MachineOptionsDrawer from '@/components/Drawers/MachineOptions.vue';
import DisconnectDialog from '@/components/Fridge/DisconnectDialog.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import { ref } from '@vue/composition-api';

export default {
    name: 'LayoutTheOrderHeader',

    components: {
        MachineOptionsDrawer,
        DisconnectDialog,
        AppIcon,
    },

    props: {
        machineType: String,
    },

    setup(_, { root }) {
        const { machine, code, authInit } = useMachine();
        const { vendingId, cartAmount } = useOrder();

        const machineOptionsOpened = ref(false);
        const isInitializingTouch = ref(false);

        /**
         * Method to toggle the disconnect dialog
         * @returns {void}
         */
        const toggleMachineOptions = () => {
            machineOptionsOpened.value = !machineOptionsOpened.value;
        };

        /**
         * Method to activate touch flow
         * @returns {void}
         */
        const initializeTouchSession = async () => {
            isInitializingTouch.value = true;
            try {
                const response = await root.$http.post(`/order/touch-session/init/${code.value}`);

                if (response.data.vendingId) {
                    vendingId.value = response.data.vendingId;
                }

                if (response.data.error) {
                    root.$toast.open({
                        message: response.data.error,
                        type: 'warning',
                    });
                }

                isInitializingTouch.value = false;
            } catch (error) {
                console.log(error);
                isInitializingTouch.value = false;
            }
        };

        if (authInit.value) {
            initializeTouchSession();
        }

        /**
         * Fridge disconnect drawer
         */
        const fridgeDisconnectDialogOpen = ref(false);

        const toggleFridgeDisconnectDialog = () => {
            fridgeDisconnectDialogOpen.value = !fridgeDisconnectDialogOpen.value;
        };

        return {
            machine,
            machineOptionsOpened,
            toggleMachineOptions,
            initializeTouchSession,
            isInitializingTouch,
            fridgeDisconnectDialogOpen,
            toggleFridgeDisconnectDialog,
            cartAmount,
        };
    },
};
</script>

<style lang="scss" scoped>
.layout-TheOrderHeader {

    @-webkit-keyframes spin {
        to { stroke-dashoffset: -264; }
    }

    @keyframes spin {
        to { stroke-dashoffset: -264; }
    }

    .spinner circle {
        fill: none;
        stroke: #1a1a1a;
        stroke-width: 16;
        stroke-linecap: round;
        stroke-dasharray: 0, 0, 70, 194;
        stroke-dashoffset: 0;
        animation: spin 1.4s infinite linear;
        -webkit-animation: spin 1.4s infinite linear;
    }
}

.mode-dark .layout-TheOrderHeader {
    .spinner circle {
        stroke: white;
    }
}
</style>
