<template>
    <!-- eslint-disable max-len -->
    <div @click="$emit('click', $event)" class="ui-AppMenuItem bg-day-04dp dark:bg-night-04dp p-4 rounded-xl flex items-center justify-between cursor-pointer">
        <div class="flex items-center">
            <slot name="icon" />
            <span class="ui-AppMenuItem_Label">{{ label }}</span>
        </div>

        <div>
            <slot name="right" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UIAppMenuItem',

    props: {
        label: String,
    },
};
</script>

<style lang="scss" scoped>
.ui-AppMenuItem {

    &_Label {
        margin-left: 10px;
    }
}
</style>
