<template>
    <!-- eslint-disable max-len -->
    <BaseDialog @close="$emit('close')" class="FridgeDisconnectDialog">
        <div class="FridgeDisconnectDialog_Message text-center">
            <h1>{{ $t('fridge.disconnect.title') }}</h1>

            <p v-if="cartAmount > 0" class="mt-1 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis" v-html="$t('fridge.disconnect.description.cart-has-items')"></p>
            <p v-else class="mt-1 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis" v-html="$t('fridge.disconnect.description.cart-empty')"></p>
        </div>

        <div class="FridgeDisconnectDialog_Actions mt-6 grid row-gap-2">
            <template v-if="cartAmount > 0">
                <AppButton @click="$emit('close')" size="small" width="full">{{ $t('fridge.disconnect.buttons.continue-scanning') }}</AppButton>

                <RouterLink :to="{ name: 'OrderCart' }">
                    <AppButton size="small" type="secondary" width="full">{{ $t('fridge.disconnect.buttons.open-cart') }}</AppButton>
                </RouterLink>
            </template>

            <template v-else>
                <AppButton @click="disconnect" size="small" width="full">{{ $t('fridge.disconnect.buttons.disconnect') }}</AppButton>
                <AppButton @click="$emit('close')" size="small" type="secondary" width="full">{{ $t('fridge.disconnect.buttons.continue-scanning') }}</AppButton>
            </template>
        </div>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Interface/BaseDialog.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import router from '@/router';

export default {
    name: 'FridgeDisconnectDialog',

    components: {
        BaseDialog,
        AppButton,
    },

    setup() {
        const { disconnectMachine } = useMachine();
        const { cartAmount } = useOrder();

        /**
         * Method to disconnect from machine and navigate back to home
         * @returns {void}
         */
        const disconnect = () => {
            disconnectMachine();
            router.push({ name: 'HomeScreen' });
        };

        return {
            disconnect,
            cartAmount,
        };
    },
};
</script>

<style lang="scss">
.FridgeDisconnectDialog {

    &_Icon {
        bottom: 16px;
        right: 64px;
    }

    &_Actions .ui-AppButton {
        @apply px-3;
    }
}
</style>
