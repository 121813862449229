<template>
    <!-- eslint-disable max-len -->
    <div @click="$emit('refill-slot', slotData)" class="operator-ShelveSlot flex justify-between items-center px-4 py-2 rounded-lg dark:bg-night-16dp">
        <div class="flex-grow flex items-center">
            <span class="dark:text-night-on-surface-low-emphasis-disabled mr-2">{{ slotData.position + 1 }}</span>

            <!-- Product + next product image -->
            <div @click.stop="swapProducts" v-if="currentProduct" class="operator-ShelveSlot_Images relative">
                <transition name="product">
                    <img v-if="currentProduct.image && !nextProductActive"
                        :src="currentProduct.image"
                        :class="['object-contain rounded-lg bg-white relative z-10']" />
                </transition>

                <!-- Next product -->
                <template v-if="nextProduct && nextProduct.image">
                    <img
                        :src="nextProduct.image"
                        class="object-contain rounded-lg bg-white"
                        :class="{ 'next-product': !nextProductActive, 'relative z-10': nextProductActive }" />
                </template>
            </div>

            <!-- Product name + next product name -->
            <div v-if="currentProduct" class="ml-4">
                <span class="block">{{ nextProductActive ? nextProduct.name : currentProduct.name }}</span>
                <!-- <span class="block text-xs dark:text-night-on-surface-low-emphasis-disabled">Next: Cerry Gums</span> -->
            </div>
        </div>

        <div class="w-20 flex-shrink-0">
            <SlotStockIndicator
                :percentage="slotData.stock_percentage ? slotData.stock_percentage : Number((slotData.stock + slotData.next_product_stock) / slotData.capacity * 100)"
                :stock="slotData.next_product_stock ? slotData.stock + slotData.next_product_stock : slotData.stock"
                :capacity="slotData.capacity" />
        </div>
    </div>
</template>

<script>
import SlotStockIndicator from '@/components/Operator/SlotStockIndicator.vue';

export default {
    name: 'ShelveSlot',

    props: {
        slotData: Object,
    },

    data() {
        return {
            currentProduct: null,
            nextProduct: null,
            nextProductActive: false,
        };
    },

    mounted() {
        this.currentProduct = this.slotData.product;
        this.nextProduct = this.slotData.next_product;
    },

    components: {
        SlotStockIndicator,
    },

    methods: {
        swapProducts() {
            if (this.nextProduct && !this.nextProductActive) {
                this.nextProductActive = true;

                setTimeout(() => {
                    this.nextProductActive = false;
                }, 2000);
            }
        },
    },
};
</script>

<style lang="scss">
.operator-ShelveSlot {

    &_Images {

        img {
            width: 52px;
            height: 52px;

            &.next-product {
                width: 44px !important;
                height: 44px !important;
                top: -4px;
                position: absolute;
                right: -6px;
                opacity: 0.24;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                z-index: 0;
            }
        }
    }

    .product-leave-active {
        transition: transform 0.3s ease;
        position: absolute;
        z-index: 20;
    }

    .product-leave-to {
        transform: translate(-5px, 30px);
        opacity: 0.5;
    }
}
</style>
