<template>
    <!-- eslint-disable max-len -->
    <div class="operator-MachineShelve p-4 rounded-2xl bg-day-04dp dark:bg-night-06dp">
        <header class="operator-MachineShelve_Heading mb-3">
            <span class="text-base flex justify-between items-center">
                <span>{{ $t('operator.shelf') }} {{ shelve.position + 1 }}</span>
                <small class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ shelve.slots.length }} {{ $t('operator.slots') }}</small>
            </span>
        </header>

        <div class="operator-MachineShelve_Slots grid gap-2">
            <ShelveSlot
                v-for="slot in shelveSlots"
                :key="`${slot.id}-${refillIteration}`"
                :slot-data="slot"
                :allow-refill="!!refillReference"
                @refill-slot="toggleRefillDrawer" />
        </div>

        <portal to="drawers" :order="3">
            <RefillDrawer
                v-if="refillDrawerActive"
                :refill-slot-data="refillSlotData"
                :reference="refillReference"
                @close="toggleRefillDrawer"
                @refilled="setUpdatedSlotStock"/>
        </portal>
    </div>
</template>

<script>
import ShelveSlot from '@/components/Operator/ShelveSlot.vue';
import RefillDrawer from '@/components/Operator/RefillDrawer.vue';
import { ref } from '@vue/composition-api';

export default {
    name: 'MachineShelve',

    props: {
        shelve: Object,
        refillReference: String,
    },

    components: {
        ShelveSlot,
        RefillDrawer,
    },

    setup(props) {
        const refillDrawerActive = ref(false);
        const refillSlotData = ref(null);

        const toggleRefillDrawer = (slotData) => {
            if (props.refillReference) {
                refillDrawerActive.value = !refillDrawerActive.value;
                refillSlotData.value = slotData;
            }
        };

        const shelveSlots = ref(props.shelve.slots);
        const refillIteration = ref(0);

        const setUpdatedSlotStock = (updatedSlotData) => {
            const index = shelveSlots.value.findIndex((slot) => slot.id === updatedSlotData.id);
            shelveSlots.value[index] = updatedSlotData;
            refillIteration.value += 1;

            toggleRefillDrawer();
        };

        return {
            refillDrawerActive,
            toggleRefillDrawer,
            refillSlotData,
            shelveSlots,
            setUpdatedSlotStock,
            refillIteration,
        };
    },
};
</script>
