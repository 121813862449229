<template>
    <!-- eslint-disable max-len -->
    <div class="FridgeOnboarding fixed z-40 inset-0 w-full bg-black h-screen flex">
        <div class="h-full w-full flex flex-col">
            <div class="flex-grow relative">
                <img :src="require(`@/assets/images/fridge-onboarding/step-${currentStep}.jpg`)" class="absolute inset-0 h-full w-full object-cover" />
            </div>

            <div class="bg-day-01dp dark:bg-night-01dp px-4 py-8 pb-32 rounded-t-2xl -mt-5 grid gap-4 relative z-10">
                <header>
                    <h3 class="block text-primary-brand">{{ $t('fridge.onboarding.step') }} {{ currentStep }}</h3>
                    <h1>{{ $t('fridge.onboarding.content')[currentStep - 1].title }}</h1>
                </header>

                <p class="text-gray-300">{{ $t('fridge.onboarding.content')[currentStep - 1].description }}</p>

                <div class="flex space-x-2">
                    <AppButton @click="nextStep" v-if="currentStep !== 3" type="primary" icon="arrow-right" icon-position="right" size="small" width="full">{{ $t('Next') }}</AppButton>
                    <AppButton @click="$emit('close')" type="secondary" size="small" width="full">{{ $t('Close') }}</AppButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppButton from '@/components/Interface/AppButton.vue';

export default {
    name: 'FridgeOnboarding',

    data() {
        return {
            currentStep: 1,
        };
    },

    components: {
        AppButton,
    },

    methods: {
        nextStep() {
            this.currentStep += 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.FridgeOnboarding {}
</style>
